import logo from "./images/logo.png"
import "./style.scss"
function App() {
  return (
    <div className="App">
    <div>
      <a><img src={logo} alt="" /></a>
       <h1>Alrawabi für das Lebensmittel <br /> Bald...</h1>
       <h1>الروابي للغذائيات قريبا</h1>
     <span class="loader"></span>
    </div>
    </div>
  );
}

export default App;
